<template>
  <div class="sign-area">
    <div class="title-wrap">
      <h2
        :class="{ kor: $i18n.locale === 'ko' }"
      > {{ $t('content.auth.SignIn.Title') }}
      </h2>
      <div class="title-desc">
        {{ $t('content.auth.SignIn.TitleDesc') }}
      </div>
    </div>

    <div class="sign-container">
      <StaggerTransition>
        <ul class="sns-sign ani-stagger">
          <li>
            <NaverLogin
              type="signIn"
            />
          </li>
          <!-- <li>
            <FacebookLogin
              type="signIn"
              @authenticated="onAuthenticated"
            />
          </li> -->
          <li>
            <GoogleLogin
              type="signIn"
              @authenticated="onAuthenticated"
            />
          </li>
          <li>
            <KakaoLogin
              type="signIn"
              @authenticated="onAuthenticated"
            />
          </li>
        </ul>
      </StaggerTransition>
    </div>
  </div>
</template>

<script>
import NaverLogin from '@/views/auth/NaverLogin.vue';
import FacebookLogin from '@/views/auth/FacebookLogin.vue';
import GoogleLogin from '@/views/auth/GoogleLogin.vue';
import KakaoLogin from '@/views/auth/KakaoLogin.vue';
import { mixin as mixinHelperUtils, mixinAuth } from '@/common/helperUtils';
import StaggerTransition from '@/components/transition/StaggerTransition.vue';

export default {
  name: 'SignIn',
  mixins: [
    mixinHelperUtils,
    mixinAuth,
  ],
  components: {
    NaverLogin,
    FacebookLogin,
    GoogleLogin,
    KakaoLogin,
    StaggerTransition,
  },
  methods: {
    onAuthenticated(oauthUserInfo) {
      this.signIn(oauthUserInfo); // mixinAuth
    },
  },
  created() {
    this.setFooter(false);
  },
};
</script>
